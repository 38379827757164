export const ApiOperation = {
  GET_PROJECTS: '/getProjects',
  GET_PROJECT: '/getProject',
  GET_USER_PROJECTS: '/getUserProjects',
  GET_USERS: '/getUsers',
  GET_USER: '/getUser',
  REGISTER_USER: '/registerUser',
  RESTORE_USER: '/restoreUser',
  DELETE_USER: '/deleteUser',
  ASSIGN_USER: '/assignUser',
  ASSIGN_USERS: '/assignUsers',
  UNASSIGN_USER: '/unassignUser',
  UNASSIGN_USERS: '/unassignUsers',
  UNASSIGN_USER_FROM_ALL_PROJECTS: '/unassignAllProjects',
  CREATE_PROJECT: '/projects',
  RESTORE_PROJECT: '/restoreProject',
  CLOSE_PROJECT: '/closeProject',
  ADD_R_PACKAGES: '/addRPackages',
  ADD_PYTHON_PACKAGES: '/addPythonPackages',
  ENABLE_USER: '/enableUser',
  DISABLE_USER: '/disableUser',
  GET_VM_OPTIONS_CONFIG: '/getVMOptionsConfig',
  GET_VMS: '/getVMs',
  GET_VM: '/getVM',
  GET_VM_STATE: '/getVMstatus',
  START_VM: '/startVM',
  STOP_VM: '/stopVM',
  UPDATE_USER: '/updateUser',
  UPDATE_USER_SELF_SERVICE: '/updateUserSelfService',
  UPDATE_PROJECT: '/updateProject',
  IS_USERNAME_UNIQUE: '/isUsernameUnique',
  IS_PROJECTID_UNIQUE: '/isProjectidUnique',
  ACTIVATE_VM: '/activateVm',
  GET_EVENT: '/getEvent',
  GET_VM_BYPASS_SHUTDOWN: '/getVmBypassShutdown',
  SET_VM_BYPASS_SHUTDOWN: '/setVmBypassShutdown',
  GET_CURRENT_LICENSE_DATA: '/getCurrentLicenseData',
  CREATE_PRODUCT: '/createProduct',
  LINK_PROJECT_TO_PRODUCTS: '/linkProjectToProducts',
  UPDATE_PRODUCT_LINKS: '/updateProductLinks',
  GET_PRODUCTS: '/getProducts',
  GET_PRODUCT: '/getProduct',
  UPDATE_PRODUCT: '/updateProduct',
  GET_PROJECTS_PRODUCTS: '/getProjectsProducts',
  DELETE_PRODUCT: '/deleteProduct',
  RESIZE_VM: '/resizeVm',
  REBUILD_VM: '/rebuildVm',
  RESTART_VM: '/restartVm',
  GET_ORGANISATIONS: '/getOrganisations',
  GET_ORGANISATION: '/getOrganisation',
  IS_ORGANISATIONNAME_UNIQUE: '/isOrganisationNameUnique',
  IS_ABN_UNIQUE: '/isABNUnique',
  CREATE_ORGANISATION: '/createOrganisation',
  DELETE_ORGANISATION: '/deleteOrganisation',
  UPDATE_ORGANISATION: '/updateOrganisation',
  REPORT_DESKTOP_SESSIONS: '/reportDesktopSessions',
  PROVISION_PROJECT_DATABRICKS: '/provisionDatabricks',
  DELETE_DATABRICKS: '/deleteDatabricks',
  GET_DATABRICKS_WORKSPACES: '/getDatabricksWorkspaces',
  GET_AAD_USERS: '/getAadUsers',
  GET_REPORTS: '/reports',
  GET_SESSIONS: '/sessions',
  REMOVE_FILE_HANDLERS: '/removeFileHandlers',
  CHANGE_VM_TYPE: '/changeVmType',
  RESET_USER_PASSWORD: '/resetUserPassword',
  CONFIGURE_VM_LOCAL_DISK: '/configureVmLocalDisk',
  SCHEDULED_MONTHLY_ACTIVE_DESKTOPS: '/scheduledMonthlyActiveDesktops',
  SCHEDULED_MONTHLY_ACTIVE_USERS: '/scheduledMonthlyActiveUsers',
  GET_MONTHLY_ACTIVE_USERS: '/getMonthlyActiveUsers',
  GET_MONTHLY_ACTIVE_DESKTOPS: '/getMonthlyActiveDesktops',
  RESET_USER_MFA: '/resetUserMFA',
  GET_SOFTWARE_LICENSE_SUMMARIES: '/getSoftwareLicenseSummaries',
  GET_TAGS: '/getTags',
  GET_TAG: '/getTag',
  CREATE_TAG: '/createTag',
  UPDATE_TAG: '/updateTag',
  SET_USER_TIMEZONE: '/setUserTimeZone',
  GET_BANNER_MESSAGE: '/getBannerMessage',
  POST_BANNER_MESSAGE: '/postBannerMessage',
  DELETE_TAG: '/deleteTag',
  LIST_ADMINS: '/listAdministrators',
  IS_AD_USERNAME_UNIQUE: '/isADUsernameUnique',
  CREATE_ADMINISTRATOR: '/createPodAdministrator',
  RESET_AD_USER_MFA: '/resetADUserMFA',
  UPDATE_AD_USER: '/updateADUser',
  DELETE_AD_USER: '/deleteADUser',
  GET_AD_USER: '/getADUser',
  TOGGLE_POD_ADMIN_ROLE: '/togglePodAdminRole',
}
