import React, {
  useContext, createContext,
} from 'react'
import { connect } from 'react-redux'
import DatalabFacade from '../dataService/DatalabFacade'
import { useLoggedInUser } from './useLoggedInUser'
import type { AnyAction, Dispatch } from 'redux'

const SeadApiContext = createContext({} as ReturnType<typeof DatalabFacade>)

/** Props for the SeadApiProvider */
interface ProviderProps {
  /** Child components within the context of the provider context */
  children: React.ReactNode
  /**
   * The redux store dispatch method for dispatching operations to the redux
   * store - this is provided by the redux connect HOC
   */
  dispatch: Dispatch<AnyAction>
}

/**
 * Provides an interface to the SEAD API. The provider must be within the
 * context of the LoggedInUserProvider for authenticating to protected APIs.
 * @param props - The provider props
 * @returns the SeadApiProvider component
 */
function Provider(props: Readonly<ProviderProps>) {
  const { children, dispatch } = props
  const { getTokenSilent } = useLoggedInUser()

  const datalabFacade = DatalabFacade(getTokenSilent, dispatch)

  return (
    <SeadApiContext.Provider value={datalabFacade}>
      {children}
    </SeadApiContext.Provider>
  )
}

const useSeadApi = () => {
  const context = useContext(SeadApiContext)
  if (context === undefined) {
    throw new Error('useSeadApi must be used within a SeadApiContext')
  }
  return context
}

const SeadApiProvider = connect()(Provider)

export {
  SeadApiProvider,
  useSeadApi,
}
