import React from 'react'
import {
  Container,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

/**
 * Page to display when no route is matched
 */
export default function PageNotFoundScreen() {
  const navigate = useNavigate()
  return (
    <Container sx={{ margin: 'auto' }} maxWidth={false}>
      <Grid container spacing={3} alignItems="center" direction="column">

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item>
            <Typography variant="body1" component="p">
              Page not found
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => navigate('/')}>
              Return home
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </Container>
  )
}

