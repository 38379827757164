"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userDtoSchema = exports.userInputFields = void 0;
const zod_1 = require("zod");
const tag_1 = require("../tag");
/** Database fields set by user input that can be queried via the api */
const userInputFields = zod_1.z.object({
    /** The UPN of the user */
    userName: zod_1.z.string(),
    /** The external contact email */
    contactEmail: zod_1.z.string(),
    /** The external contact phone number */
    phone: zod_1.z.string(),
    /** The pod identifier ie. ABS */
    podId: zod_1.z.string(),
    /** The users organisation */
    organisation: zod_1.z.string(),
    /** The display name for the user */
    displayName: zod_1.z.string(),
    /** List of tag instances to associate with the user */
    tags: zod_1.z.array(tag_1.tagInstanceDtoSchema)
        .default([]),
});
exports.userInputFields = userInputFields;
/** Generated database fields that can be queried via the api */
const generatedFields = zod_1.z.object({
    status: zod_1.z.enum(['ACTIVE', 'DISABLED', 'INACTIVE']),
    registeredDate: zod_1.z.string().datetime(),
    activeVm: zod_1.z.string(),
    localTimeZone: zod_1.z.string(),
    tags: zod_1.z.array(tag_1.tagInstanceDtoSchema),
});
/** All database fields that are exposed via API */
const userDtoSchema = userInputFields.merge(generatedFields);
exports.userDtoSchema = userDtoSchema;
