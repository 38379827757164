import React, { useState } from 'react'
import { Alert } from '@mui/material'
import { useSeadApi } from '../hooks/useSeadApi'
import { getRuntimeConfig } from '../util/Config'
import { SeadAccountInfo, useLoggedInUser } from '../hooks/useLoggedInUser'
import { Role } from '../enums/Role'

const config = getRuntimeConfig()

/**
 * Displays alerts at the top of the app. Maintenance/outage alerts are
 * displayed in yellow to all users. Business announcements/banners are
 * displayed in blue and are pod specific.
 */
export default function AppAlerts() {
  const [bannerMsg, setBannerMsg] = useState<string | null>()
  const { getBannerMessage, setUserTimeZone } = useSeadApi()
  const { user } = useLoggedInUser()

  /** Gets banner message + sets the user's timezone upon login */
  const initializeUserBackgroundTasks = async (loggedInUser: SeadAccountInfo) => {
    const { message } = await getBannerMessage({ podId: loggedInUser.pod })
    setBannerMsg(message)
    if (loggedInUser.roles.includes(Role.ANALYST)) {
      await setUserTimeZone(loggedInUser.username, loggedInUser.localTimeZone)
    }
  }

  React.useEffect(() => {
    /**
     * Fetch any banner message to display in the webapp
     */
    if (user) {
      initializeUserBackgroundTasks(user)
    }
  }, [user])

  /**
   * An array of all alerts to be shown in the UI.
   * Only alerts with `visible: true` and a non-empy mesage will appear
   * @member {object[]} alerts
   */
  const alerts = [
      {
        name: 'maintenance',
        type: 'warning',
        message: config.MAINTENANCE_MESSAGE,
        visible: true,
      } as const,
      {
        name: 'banner',
        type: 'info',
        message: bannerMsg,
        visible: !!user,
      } as const,
  ]

  return (
    <>
      {alerts.map((alert) => (
        alert.visible && alert.message && (
        <Alert severity={alert.type} key={alert.name}>
          {alert.message}
        </Alert>
        )
      ))}
    </>
  )
}

