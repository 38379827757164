/** The application roles granted to users */
export enum Role {
  SYSADMIN = 'sysadmin',
  ADMIN = 'admin',
  DATA_ADMIN = 'dataadmin',
  USER_ADMIN = 'useradmin',
  ANALYST = 'analyst',
  READONLY = 'readonly',
  POD_OWNER = 'podowner',
  LIBRARY_ADMIN = 'libraryadmin',
  BILLING_ADMIN = 'billingadmin',
}

const podAdminRoles = [
  Role.ADMIN,
  Role.DATA_ADMIN,
  Role.USER_ADMIN,
  Role.POD_OWNER,
  Role.READONLY,
  Role.SYSADMIN,
]

export const hasPodAdminRole = (roles: Role[]) => roles.some(
  (r) => podAdminRoles.includes(r)
)
