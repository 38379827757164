import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import AdminRoutes from './routes/AdminRoutes'
import AnalystRoutes from './routes/AnalystRoutes'
import LibraryAdminRoutes from './routes/LibraryAdminRoutes'
import BillingAdminRoutes from './routes/BillingAdminRoutes'
import PodOwnerRoutes from './routes/PodOwnerRoutes'
import { Role } from './enums/Role'
import { useLoggedInUser } from './hooks/useLoggedInUser'
import { useSeadApi } from './hooks/useSeadApi'
import { getRuntimeConfig } from './util/Config'
import SpinnerIndeterminate from './components/SpinnerIndeterminate'
import PageNotFoundScreen from './screens/common/PageNotFoundScreen'

const config = getRuntimeConfig()

/** Maps the screen component to the URL path it should be rendered on */
type Path = {
  /** The URL path */
  path: string;
  /** Title of the page */
  name: string;
  /** Component to render */
  Component: typeof React.Component;
}

const ROUTE_CONFIG: {
  /** An array of path configs */
  paths: Path[],
  /** An array of roles that are allowed to access the path */
  roles: string[]
}[] = [
  {
    paths: AdminRoutes,
    roles: [Role.ADMIN, Role.USER_ADMIN, Role.DATA_ADMIN, Role.SYSADMIN, Role.READONLY],
  },
  {
    paths: AnalystRoutes,
    roles: [Role.ANALYST],
  },
  {
    paths: LibraryAdminRoutes,
    roles: [Role.LIBRARY_ADMIN, Role.SYSADMIN],
  },
  {
    paths: PodOwnerRoutes,
    roles: [Role.POD_OWNER, Role.SYSADMIN],
  },
  {
    paths: BillingAdminRoutes,
    roles: [Role.BILLING_ADMIN, Role.SYSADMIN],
  },
]

/**
 * Check if any from a list of roles appears in a list of available roles
 * @param roles - The users roles
 * @param availableRoles - All roles
 * @returns True if roles and availableRoles intersect
 */
const hasRole = (roles: string[], availableRoles: string[]): boolean => (
  roles.some((r) => availableRoles.includes(r))
)

/**
 * Component to display for MsalAuthenticationTemplate when an error occurs
 * @param authResult - result from the auth interaction
 */
function ErrorComponent(authResult: MsalAuthenticationResult) {
  const { error } = authResult
  const message = `An error occurred: ${error ?? ''}`
  return (
    <p>{message}</p>
  )
}

/**
 * Component to display for MsalAuthenticationTemplate when loading occurs
 */
function LoadingComponent() {
  return <SpinnerIndeterminate />
}

/**
 * DataLab app router component
 * @returns the Router component
 */
function Router() {
  const { user } = useLoggedInUser()
  const datalabFacade = useSeadApi()

  // Find all route groups that match current user role
  const configs = ROUTE_CONFIG.filter(({ roles }) => hasRole(user ? user.roles : [], roles))
  // Combine all Route elements from matched groups into a single array
  const routes = configs.reduce((acc: React.ReactElement[], { paths }) => ([
    ...acc,
    ...paths.map(({ path, Component }) => (
      <Route
        path={path}
        key={path}
        element={(
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes: [config.SCOPE_INVOKE] }}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
          >
            <Component
              routes={paths}
                // The below props are deprecated and only provided to support
                // unmigrated components, you should use the relevant context hooks
                // (as this component itself does) to access these objects within
                // components
              user={user}
              datalabFacade={datalabFacade}
            />
          </MsalAuthenticationTemplate>
        )}
      />
    )),
  ]), [])

  return (
    <Routes>
      {routes}
      <Route
        path="/*"
        element={(
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes: [config.SCOPE_INVOKE] }}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
          >
            <PageNotFoundScreen />
          </MsalAuthenticationTemplate>
        )}
      />
    </Routes>
  )
}

export default Router
export type { Path }
