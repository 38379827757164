import React from 'react'
import { createRoot } from 'react-dom/client'
import 'typeface-roboto'
import { MsalProvider } from '@azure/msal-react'
import Datalab from './js/Datalab'
import { LoggedInUserProvider } from './js/hooks/useLoggedInUser'
import { setupMsal } from './msal'
import type { PublicClientApplication } from '@azure/msal-browser'

setupMsal().then((msalInstance: PublicClientApplication) => {
  const rootElement = document.getElementById('root')
  if (!rootElement) throw new Error('Could not find reference to root element')
  const root = createRoot(rootElement)
  root.render(
    <MsalProvider instance={msalInstance}>
      <LoggedInUserProvider>
        <Datalab />
      </LoggedInUserProvider>
    </MsalProvider>
  )
})

