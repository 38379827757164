import moment from 'moment-timezone'

/**
 * Converts milli-seconds into hours/minutes
 * @param millis - number of milli seconds
 * @returns - formatted string of hours and minutes
 */
function millisToHoursMinutes(millis: number) {
  const h = Math.floor(millis / 1000 / 60 / 60)
  const m = Math.floor((millis / 1000 / 60 / 60 - h) * 60)
  const hours = h > 0 ? `${h} hours` : ''
  return `${hours} ${m} minutes`
}

/**
 * Returns the local time, given the specified timezone and hour.
 * @param hour - the hour being set. E.g: 20 = 8pm
 * @param timeZone - local time zone being converted to
 * @returns - the local time
 */
function hourInLocalTime(hour: number, timeZone?: string) {
  const m = moment().tz(timeZone ?? 'Australia/Sydney')
  m.set({
    hour, minute: 0, second: 0, millisecond: 0,
  })
  const localHour = m.tz(moment.tz.guess()).format('h:mm A')
  return localHour
}

/**
 * Given a Date returns either "Tonight" or the weekday night ie "Thursday night"
 * @param dateValue - the date
 * @param timeZone - local time zone being set
 * @returns - name of the day (e.g. "Tonight" or "Thursday night"), with local timezone
 */
function dateFutureDayOrTonight(dateValue: Date, timeZone?: string) {
  const str = `Tonight 8PM (${timeZone ?? 'Australia/Sydney'})`
  if (!dateValue) {
    return str
  }
  const now = new Date()
  if (dateValue < now) {
    return str
  }

  const message = `${dateValue.toLocaleDateString('default', { weekday: 'long' })} night 8PM (${timeZone ?? 'Australia/Sydney'})`
  return message
}

export {
  millisToHoursMinutes,
  hourInLocalTime,
  dateFutureDayOrTonight,
}
