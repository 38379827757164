import { asyncOperationResponse, getBannerMessageResponse } from '@datalab/dto-schemas'
import { AnyAction, Dispatch } from 'redux'
import { StoreAction } from '../enums/StoreAction'
import { ApiOperation } from '../enums/ApiOperation'
import { getRuntimeConfig } from '../util/Config'
import { UserFixableError } from '../util/Exceptions'
import dataService from './DataService'
import type {
  FindAllPaginatedQuery,
  FindAllPaginatedQueryResponse,
  FindAllPaginatedQueryResponseFields,
  GetBannerMessageRequest,
  GetBannerMessageResponse,
} from '@datalab/dto-schemas'

const config = getRuntimeConfig()
const { fetchData, fetchOne, postOperation } = dataService
const apiBaseUrl = config.PROPERTIES_STAGE === 'local'
  ? 'https://datalab.dev.abs.gov.au/api/operations'
  : `${window.location.origin}/api/operations`

/**
 *
 */
function checkBlockWrites() {
  if (config.MAINTENANCE_BLOCK_WRITES === 'true') {
    throw new UserFixableError(`This feature is temporarily disabled. ${config.MAINTENANCE_MESSAGE}`)
  }
}

/**
 *
 */
function checkBlockPodOverride() {
  if (window.localStorage.getItem('sysadmin.pod_override')) {
    throw new UserFixableError('Currently read-only access when using pod override')
  }
}

/**
 *
 * @param getAccessToken
 * @param asyncOperationDispatcher
 * @param endpoint
 * @param requestBody
 * @param snackbarTracker
 */
async function invokeAsyncApiOperation(
  getAccessToken: () => Promise<string>,
  asyncOperationDispatcher: Dispatch<AnyAction>,
  endpoint: string,
  requestBody: Record<string, unknown>,
  snackbarTracker?: () => void
) {
  const accessToken = await getAccessToken()
  const result = await postOperation(accessToken, apiBaseUrl + endpoint, requestBody)
  const { eventId } = asyncOperationResponse.parse(result.data)
  if (!eventId) {
    throw new Error('An async function operation returned successfully but didnt provide an eventId')
  }
  let snackbar
  // if tracking via snackbar
  if (snackbarTracker) {
    snackbar = snackbarTracker()
  }
  // push the async function operation on the queue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- The redux store needs typing
  asyncOperationDispatcher({
    type: StoreAction.ADD_OPERATION,
    operation: {
      eventId, time: new Date().getTime(), endpoint, requestBody, snackbar,
    },
  })
}

export default (getAccessToken: () => Promise<string>, asyncOperationDispatcher: Dispatch<AnyAction>) => ({

  /**
   *
   * @param eventIds
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getEventsByIds: async (eventIds) => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_EVENT,

      { eventIds }
    )
    return data
  },

  /**
   *
   */
  getDatabricksWorkspaces: async () => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_DATABRICKS_WORKSPACES
    )
    return data
  },

  /**
   *
   */
  getVMOptionsConfig: async () => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_VM_OPTIONS_CONFIG
    )
    return data
  },

  /**
   * List documents in a collection
   * @param params - Options for the list operation
   * @returns The documents
   */
  list: async<
    T extends FindAllPaginatedQuery &
    { requestBody: { fields: (keyof FindAllPaginatedQueryResponseFields[T['entity']])[] } }
  >(params: T): Promise<FindAllPaginatedQueryResponse<T['entity'], T['requestBody']['fields']>> => {
    const accessToken = await getAccessToken()
    const { entity, requestBody } = params
    const result = await postOperation(
      accessToken,
      `${apiBaseUrl}/list/${entity}`,
      requestBody
    )
    return result.data as FindAllPaginatedQueryResponse<T['entity'], T['requestBody']['fields']>
  },

  /**
   *
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getUsers: async (podId) => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_USERS,

      { podId }
    )
    return data
  },

  /**
   *
   */
  getCurrentLicenseData: async () => {
    const accessToken = await getAccessToken()
    return fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_CURRENT_LICENSE_DATA
    )
  },

  /**
   *
   * @param projectId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getProject: async (projectId) => {
    const accessToken = await getAccessToken()
    const project = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_PROJECT,

      { projectID: projectId }
    )
    return project
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getUserProjects: async (userName) => {
    const accessToken = await getAccessToken()
    const projects = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_USER_PROJECTS,

      { userName }
    )
    return projects
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getUser: async (userName) => {
    const accessToken = await getAccessToken()
    const user = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_USER,

      { userName }
    )
    return user
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getADUser: async (userName) => {
    const accessToken = await getAccessToken()
    const user = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_AD_USER,

      { userPrincipalName: userName }
    )
    return user
  },

  /**
   *
   * @param VMname
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getVm: async (VMname) => {
    const accessToken = await getAccessToken()
    return fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_VM,

      { vmName: VMname }
    )
  },

  /**
   *
   */
  getVms: async () => {
    const accessToken = await getAccessToken()
    return fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_VMS,

      {}
    )
  },

  /**
   *
   * @param VMname
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getVmState: async (VMname) => {
    const accessToken = await getAccessToken()
    return fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_VM_STATE,

      { vmName: VMname }
    )
  },

  /**
   *
   * @param vmName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getVmBypassShutdown: async (vmName) => {
    const accessToken = await getAccessToken()
    return fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_VM_BYPASS_SHUTDOWN,

      { vmName }
    )
  },

  /**
   *
   * @param vmName
   * @param bypassDays
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  setVmBypassShutdown: async (vmName, bypassDays) => {
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.SET_VM_BYPASS_SHUTDOWN,

      { vmName, bypassDays }
    )
    return result.data
  },

  /**
   *
   * @param projectId
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  provisionProjectToDatabricks: async (projectId, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(
      getAccessToken,
      asyncOperationDispatcher,
      ApiOperation.PROVISION_PROJECT_DATABRICKS,
      { projectId, podId }
    )
  },

  /**
   * Delete the databricks workspace associated with a project
   * @param projectId - The project identifier
   * @param podId     - The pod identifier
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  deleteDatabricks: async (projectId, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(
      getAccessToken,
      asyncOperationDispatcher,
      ApiOperation.DELETE_DATABRICKS,
      { projectId, podId }
    )
  },

  /**
   *
   * @param vmName
   * @param podId
   * @param snackbarTracker
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  startVM: async (vmName, podId, snackbarTracker) => {
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.START_VM, { vmName, podId }, snackbarTracker)
  },

  /**
   *
   * @param vmName
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  stopVM: async (vmName, podId) => {
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.STOP_VM, { vmName, podId })
  },

  /**
   *
   * @param project
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  createProject: async (project) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.CREATE_PROJECT, project)
  },

  /**
   *
   * @param projectId
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  restoreProject: async (projectId, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.RESTORE_PROJECT, { uuid: projectId, podId })
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  isUsernameUnique: async (userName) => {
    const body = {
      userName,
    }
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.IS_USERNAME_UNIQUE,

      body
    )
    return result.data
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  isADUsernameUnique: async (userName) => {
    const body = {
      userPrincipalName: userName,
    }
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.IS_AD_USERNAME_UNIQUE,

      body
    )
    return result.data
  },

  /**
   *
   * @param uuid
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  isProjectidUnique: async (uuid) => {
    const body = {
      uuid,
    }
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.IS_PROJECTID_UNIQUE,

      body
    )
    return result.data
  },

  /**
   * Invoke the add packages api endpoint
   * @param packageNames - Array of package names
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  addRPackages: async (packageNames) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.ADD_R_PACKAGES, { packages: packageNames })
  },

  /**
   * Invoke the add packages api endpoint
   * @param packageNames - Array of package names
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  addPythonPackages: async (packageNames) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.ADD_PYTHON_PACKAGES, { packages: packageNames })
  },

  /**
   *
   * @param projectId
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  closeProject: async (projectId, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.CLOSE_PROJECT, { projectId, podId })
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  disableUser: async (userName) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.DISABLE_USER, { userName })
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  enableUser: async (userName) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.ENABLE_USER, { userName })
  },

  /**
   *
   * @param user
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  createUser: async (user) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.REGISTER_USER, user)
  },

  /**
   *
   * @param user
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  createAdministrator: async (user) => {
    checkBlockWrites()
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.CREATE_ADMINISTRATOR,

      user
    )
  },

  /**
   *
   * @param userPrincipalName
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  deleteADUser: async (userPrincipalName, podId) => {
    checkBlockWrites()
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.DELETE_AD_USER,
      { userPrincipalName, podId }
    )
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  resetADUserMFA: async (userName) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.RESET_AD_USER_MFA,
      { userName }
    )
    return result.data
  },

  /**
   *
   * @param user
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateADUser: async (user) => {
    checkBlockWrites()
    const accessToken = await getAccessToken()
    await postOperation(accessToken, apiBaseUrl + ApiOperation.UPDATE_AD_USER, user)
  },

  /**
   *
   * @param userPrincipalName
   * @param type
   * @param enabled
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  togglePodAdminRole: async (userPrincipalName, type, enabled) => {
    checkBlockWrites()
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.TOGGLE_POD_ADMIN_ROLE,
      { userPrincipalName, type, enabled }
    )
  },

  /**
   *
   * @param user
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  restoreUser: async (user) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.RESTORE_USER, user)
  },

  /**
   *
   * @param user
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateUser: async (user) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    await postOperation(accessToken, apiBaseUrl + ApiOperation.UPDATE_USER, user)
  },

  /**
   *
   * @param user
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateUserSelfService: async (user) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.UPDATE_USER_SELF_SERVICE,

      user
    )
  },

  /**
   *
   * @param project
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateProject: async (project) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const projectRequest = project
    projectRequest.projectId = project.uuid
    delete projectRequest.uuid
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.UPDATE_PROJECT,
      projectRequest
    )
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  evictUser: async (userName) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.DELETE_USER, { userName })
  },

  /**
   *
   * @param userName
   * @param projectId
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  unassignUserFromProject: async (userName, projectId, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const submitData = {
      projectId,
      userName,
      podId,
    }
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.UNASSIGN_USER, submitData)
  },

  /**
   *
   * @param userNames
   * @param projectId
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  unassignUsersFromProject: async (userNames, projectId, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const submitData = {
      projectId,
      userNames,
      podId,
    }
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.UNASSIGN_USERS, submitData)
  },

  /**
   *
   * @param userName
   * @param projectIds
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  unassignAllProjects: async (userName, projectIds, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const submitData = {
      projectIds,
      userName,
      podId,
    }
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.UNASSIGN_USER_FROM_ALL_PROJECTS, submitData)
  },

  /**
   *
   * @param userName
   * @param projectId
   * @param size
   * @param type
   * @param version
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  assignUserToProject: async (userName, projectId, size, type, version, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const submitData = {
      size,
      type,
      version,
      projectId,
      userName,
      podId,
    }
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.ASSIGN_USER, submitData)
  },

  /**
   *
   * @param userNames
   * @param projectId
   * @param size
   * @param type
   * @param version
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  assignUsersToProject: async (userNames, projectId, size, type, version, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const submitData = {
      size,
      type,
      version,
      projectId,
      userNames,
      podId,
    }
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.ASSIGN_USERS, submitData)
  },

  /**
   *
   * @param vmName
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  activateVm: async (vmName, podId) => {
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.ACTIVATE_VM, { vmName, podId })
  },

  /**
   *
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getProducts: async (podId) => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_PRODUCTS,

      { podId }
    )
    return data
  },
  /**
   *
   * @param name
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getProduct: async (name, podId) => {
    const requestBody = {
      name,
      podId,
    }
    const accessToken = await getAccessToken()
    const product = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_PRODUCT,

      requestBody
    )
    return product
  },

  /**
   *
   * @param projectId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getProjectsProducts: async (projectId) => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_PROJECTS_PRODUCTS,

      { projectId }
    )
    return data
  },

  /**
   *
   * @param product
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  createProduct: async (product) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.CREATE_PRODUCT, product)
  },

  /**
   *
   * @param name
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  deleteProduct: async (name, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.DELETE_PRODUCT, { name, podId })
  },

  /**
   *
   * @param product
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateProduct: async (product) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.UPDATE_PRODUCT,

      product
    )
  },

  /**
   *
   * @param projectId
   * @param productNames
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  linkProjectToProducts: async (projectId, productNames) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.LINK_PROJECT_TO_PRODUCTS, { projectId, productNames })
  },

  /**
   *
   * @param body
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateProductLinks: async (body) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.UPDATE_PRODUCT_LINKS, body)
  },

  /**
   *
   * @param resourceGroupName
   * @param vmName
   * @param vmSize
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  resizeVm: async (resourceGroupName, vmName, vmSize) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const requestBody = {
      resourceGroupName,
      vmName,
      vmSize,
    }
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.RESIZE_VM, requestBody)
  },

  /**
   *
   * @param vmName
   * @param type
   * @param version
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  changeVmType: async (vmName, type, version, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const requestBody = {
      vmName,
      type,
      version,
      podId,
    }
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.CHANGE_VM_TYPE, requestBody)
  },

  /**
   *
   * @param vmName
   * @param podId
   * @param snackbarTracker
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  restartVm: async (vmName, podId, snackbarTracker) => {
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.RESTART_VM, { vmName, podId }, snackbarTracker)
  },

  /**
   *
   * @param vmName
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  rebuildVm: async (vmName, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.REBUILD_VM, { vmName, podId, touched: true })
  },

  /**
   *
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getOrganisations: async (podId) => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_ORGANISATIONS,

      { podId }
    )
    return data
  },

  /**
   *
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getPodAdmins: async (podId) => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.LIST_ADMINS,

      { podId }
    )
    return data
  },

  /**
   *
   * @param organisationId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getOrganisation: async (organisationId) => {
    const accessToken = await getAccessToken()
    const organisation = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_ORGANISATION,

      { organisationId }
    )
    return organisation
  },

  /**
   *
   * @param organisationName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  isOrganisationNameUnique: async (organisationName) => {
    const body = {
      organisationName,
    }
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.IS_ORGANISATIONNAME_UNIQUE,

      body
    )
    return result.data
  },

  /**
   *
   * @param ABN
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  isABNUnique: async (ABN) => {
    const body = {
      ABN,
    }
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.IS_ABN_UNIQUE,

      body
    )
    return result.data
  },

  /**
   *
   * @param organisation
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  createOrganisation: async (organisation) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.CREATE_ORGANISATION,

      organisation
    )
    return result.data
  },

  /**
   *
   * @param organisationId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  deleteOrganisation: async (organisationId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const body = {
      organisationId,
    }

    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.DELETE_ORGANISATION,

      body
    )
    return result.data
  },

  /**
   *
   * @param organisation
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateOrganisation: async (organisation) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.UPDATE_ORGANISATION,

      organisation
    )
  },

  /**
   *
   */
  getAadUsers: async () => {
    const accessToken = await getAccessToken()
    return fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_AAD_USERS
    )
  },

  /**
   *
   */
  getReports: async () => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_REPORTS
    )
    return data
  },

  /**
   *
   * @param fileName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getReport: async (fileName) => {
    const accessToken = await getAccessToken()
    return fetchOne(
      accessToken,
      `${apiBaseUrl + ApiOperation.GET_REPORTS}/${fileName}`,

      {}
    )
  },

  /**
   *
   * @param productName
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  removeFileHandlers: async (productName, podId) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.REMOVE_FILE_HANDLERS,

      { productName, podId }
    )
  },

  /**
   *
   * @param userDetails
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  resetUserPassword: async (userDetails) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.RESET_USER_PASSWORD,
      userDetails
    )
    return result.data
  },

  /**
   *
   * @param vmName
   * @param localDiskSize
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  configureVmLocalDisk: async (vmName, localDiskSize) => {
    checkBlockWrites()
    checkBlockPodOverride()
    await invokeAsyncApiOperation(getAccessToken, asyncOperationDispatcher, ApiOperation.CONFIGURE_VM_LOCAL_DISK, { vmName, localDiskSize })
  },

  /**
   *
   */
  getMonthlyActiveDesktops: async () => {
    const accessToken = await getAccessToken()
    const result = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_MONTHLY_ACTIVE_DESKTOPS
    )
    return result
  },

  /**
   *
   */
  getMonthlyActiveUsers: async () => {
    const accessToken = await getAccessToken()
    const result = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_MONTHLY_ACTIVE_USERS
    )
    return result
  },

  /**
   *
   * @param userName
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  resetUserMFA: async (userName) => {
    checkBlockWrites()
    checkBlockPodOverride()
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.RESET_USER_MFA,

      { userName }
    )
    return result.data
  },

  /**
   *
   * @param startDate
   * @param endDate
   * @param softwareNames
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getSoftwareLicenseSummaries: async (startDate, endDate, softwareNames) => {
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.GET_SOFTWARE_LICENSE_SUMMARIES,

      { startDate, endDate, softwareNames }
    )
    return result.data
  },

  /**
   *
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getTags: async (podId) => {
    const accessToken = await getAccessToken()
    return fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_TAGS,
      { podId }
    )
  },

  /**
   *
   * @param tagKey
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  getTag: async (tagKey, podId) => {
    const accessToken = await getAccessToken()
    const result = await fetchOne(
      accessToken,
      apiBaseUrl + ApiOperation.GET_TAG,
      { tagKey, podId }
    )
    return result
  },

  /**
   *
   * @param tag
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  createTag: async (tag) => {
    const accessToken = await getAccessToken()
    const result = await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.CREATE_TAG,

      tag
    )
    return result.data
  },

  /**
   *
   * @param tag
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  updateTag: async (tag) => {
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.UPDATE_TAG,

      tag
    )
  },

  /**
   * Updates the user's timezone in the DB
   * @param userName - name of the user
   * @param localTimeZone - timeZone the user is located in. Uses the IANA timeZone standard.
   *                       https://timeapi.io/documentation/iana-timezones
   */
  setUserTimeZone: async (userName: string, localTimeZone: string) => {
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.SET_USER_TIMEZONE,
      { userName, localTimeZone }
    )
  },

  /**
   *
   * @param getBannerMessageRequest
   */
  getBannerMessage: async (getBannerMessageRequest: GetBannerMessageRequest):
    Promise<GetBannerMessageResponse> => {
    const accessToken = await getAccessToken()
    const data = await fetchData(
      accessToken,
      apiBaseUrl + ApiOperation.GET_BANNER_MESSAGE,
      getBannerMessageRequest
    )
    return getBannerMessageResponse.parse(data)
  },

  /**
   *
   * @param message
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  postBannerMessage: async (message) => {
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.POST_BANNER_MESSAGE,
      message
    )
  },

  /**
   *
   * @param tagKey
   * @param podId
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- You should type this if changing this code
  deleteTag: async (tagKey, podId) => {
    const accessToken = await getAccessToken()
    await postOperation(
      accessToken,
      apiBaseUrl + ApiOperation.DELETE_TAG,
      { tagKey, podId }
    )
  },
})
