import React from 'react'
import {
  Box,
  Button,
  Drawer,
} from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGemoji from 'remark-gemoji'
import CloseIcon from '@mui/icons-material/Close'
import WhatsNewMarkdown from '../../whatsnew.md'

/** DrawerWhatsNew props */
interface Props {
    /** Whether the component is shown or hidden */
    open: boolean
    /** Callback to invoke when the drawer is closed */
    closeCallback: () => void
}

/**
 * Component displays information about whats new in the system as a slide out
 * blade on the right of the screen
 */
function DrawerWhatsNew(props: Readonly<Props>) {
  const { open, closeCallback } = props
  const [content, setContent] = React.useState({ md: '' })

  React.useEffect(() => {
    fetch(WhatsNewMarkdown)
      .then((res) => res.text())
      .then((md) => {
        setContent({ md })
      })
  }, [])

  return (

    <Drawer
      anchor="right" // Determines where on the screen the drawer will appear
      open={open}
    >
      <Box
        sx={{ padding: 5, width: '800px' }}
        role="presentation"
        className="md-content"
      >
        <Button
          variant="text"
          startIcon={<CloseIcon />}
          onClick={closeCallback}
          sx={{ float: 'right' }}
        >
          Close
        </Button>
        <ReactMarkdown remarkPlugins={[remarkGemoji]}>
          {content.md}
        </ReactMarkdown>
      </Box>
    </Drawer>

  )
}

export default DrawerWhatsNew
