import React, { useEffect, useState } from 'react'
import flow from 'lodash.flow'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Button,
  Container,
} from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import SkeletonForm from '../../components/SkeletonForm'
import { useSeadApi } from '../../hooks/useSeadApi'
import { useFeatures } from '../../hooks/useFeatures'
import { useLoggedInUser } from '../../hooks/useLoggedInUser'
import { useSnackbars } from '../../hooks/useSnackbars'

/** Fields for the input form on the banner messages screen */
interface FormValues {
  /** The banner message itself */
  messageText: string | null;
}

// Screen requires the following data providers and features injected
const wrap = flow([
  asBaseScreen,
])

/**
 * Screen for managing banner messages
 * @returns the BannerMessagesScreen component
 */
function BannerMessagesScreen() {
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState<string | null>(null)

  const { setConfirm } = useFeatures()
  const { showSnackbarSuccess } = useSnackbars()
  const { getBannerMessage, postBannerMessage } = useSeadApi()
  const { user } = useLoggedInUser()

  useEffect(() => {
    if (!user) {
      return
    }
    getBannerMessage({ podId: user.pod })
      .then((response) => {
        setMessage(response.message)
        setLoading(false)
      })
  }, [user])

  const formInitialValues: FormValues = {
    messageText: message,
  }

  const handleSubmitForm = (values: FormValues) => {
    if (!user) throw new TypeError('No user account present')
    const req = {
      podId: user.pod,
      message: values.messageText,
    }

    setConfirm({
      message:
      'Are you sure you want to post this message?',
      callback: async () => {
        await postBannerMessage(req)
        showSnackbarSuccess(
          `Post Banner Message (${req.message}) succeeded`
        )
        window.location.reload()
      },
    })
  }

  const handleRemove = () => {
    if (!user) throw new TypeError('No user account present')
    setConfirm({
      message:
      'Are you sure you want to remove this message?',
      callback: async () => {
        await postBannerMessage({
          message: '',
          podId: user.pod,
        })
        showSnackbarSuccess(
          'Remove Banner Message succeeded'
        )
        window.location.reload()
      },
    })
  }

  return (
    loading ? <SkeletonForm />
      : (
        <div className="details-grid">
          <Container maxWidth="md">

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={formInitialValues}
              onSubmit={(values) => { handleSubmitForm(values) }}
              validationSchema={Yup.object().shape({
                messageText: Yup.string()
                  .trim().min(1).required()
                  .label('Banner Message'),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} alignItems="center" direction="column">

                      <Grid
                        container
                        className="formRow"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                        direction="row"
                      >
                        <Grid item xs={2}>
                          <InputLabel htmlFor="messageText">Message</InputLabel>
                        </Grid>
                        <Grid item xs>
                          <FormControl fullWidth>
                            <TextField
                              id="messageText"
                              aria-describedby="helperMessageText"
                              multiline
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter message here"
                              value={values.messageText}
                              inputProps={{ maxLength: 400 }}
                            />

                            <FormHelperText
                              id="helperMessageText"
                              error={!!errors.messageText && !!touched.messageText}
                            >
                              {(errors.messageText && touched.messageText) ? errors.messageText
                                : ''}
                              {`${values.messageText?.length ?? 0}/400`}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className="action-button-container">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={message === values.messageText}
                      >
                        Submit
                      </Button>
                    </div>

                    <div className="action-button-container">
                      <Button variant="contained" color="primary" onClick={handleRemove}>
                        Clear banner
                      </Button>
                    </div>

                  </form>
                )
              }}
            </Formik>
          </Container>
        </div>
      )
  )
}

export default wrap(BannerMessagesScreen)

