import React from 'react'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import {
  Grid,
  Button,
  Typography,
  ButtonGroup,
  Paper,
  IconButton,
  TextField,
} from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import HelpIcon from '@mui/icons-material/Help'
import { connect } from 'react-redux'
import { Alert } from 'reactstrap'
import Autocomplete from '@mui/material/Autocomplete'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withVm, withLoggedInUser, withAllVmOptionsConfig } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import ComplexAttributeRow from '../../components/ComplexAttributeRow'
import ArrayAttributeRow from '../../components/ArrayAttributeRow'
import { formatSizeUsage } from '../../util/numberFormatting'
import { hourInLocalTime } from '../../util/StringUtils'
import RebuildProgress from '../../components/RebuildProgress'
import HelpDialog from '../../components/HelpDialog'
import HelpDialogListed from '../../components/HelpDialogListed'
import SkeletonArrayRow from '../../components/SkeletonArrayRow'
import { StoreAction } from '../../enums/StoreAction'

const SectionHeader = styled('div')({
  padding: '10px',
  '& h3': {
    display: 'inline',
    margin: '5px',
  },
})

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
})

const ScrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
  height: '50vh',
  overflowY: 'auto',
})

const DaySelectorButton = styled(Button)({
  '&:focus': {
    outline: '0px',
  },
})

const HelpIconButton = styled(IconButton)({
  verticalAlign: 'text-bottom',
  padding: '0px',
})

const SaveButtonContainer = styled('div')({
  padding: '20px',
  '& button': {
    margin: '10px',
  },
  width: '100%',
  textAlign: 'center',
})

const FormLabel = styled(TextField)({
  '& .MuiFormLabel-root': {
    marginTop: '3px',
  },
  '& .MuiInputBase-root': {
    height: 60,
  },

})

const AutocompleteBox = styled('div')({
  display: 'flex',
  flex: 'auto',
})

// Screen requires the following data providers and features injected
const wrap = flow([
  withVm,
  withAllVmOptionsConfig,
  withLoggedInUser,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

/**
* Displays VM details in simple list view
*/
class VmDetailsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      loading: true,
      vmActive: undefined,
      vm: undefined,
      powerState: undefined,
      shutdownBypassUntil: undefined,
      redeploymentDueDate: undefined,
      settingShutdownBypass: false,
      selectedBypassDays: undefined,
      project: undefined,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.loading && this.props.userData && this.props.vmOptionsConfig && this.props.vmData) {
      const vm = this.props.vmData

      // Separate the different config options
      const vmVersionOptions = this.props.vmOptionsConfig.filter((x) => x.configType === 'vmVersion')

      // Find the vms current config options
      const currentVmVersionOption = vmVersionOptions.find((vmVersionOption) => vmVersionOption.version === vm.version)

      // Calculate the config options the vm could change to
      const selectableVmVersions = vmVersionOptions.filter((vmVersionOption) => vmVersionOption.availableForRebuild)

      this.setState({
        vmActive: (this.props.userData.activeVm === vm.VMname),
        vm,
        loading: false,
        currentVmVersionOption,
        selectableVmVersions,
        selectedVmVersion: currentVmVersionOption.availableForRebuild ? currentVmVersionOption : selectableVmVersions.find((e) => e.latest),
        vmVersionOptions,
      })
    }
  }

  handleChangeVmVersion = () => {
    this.props.askForConfirmationListener(`Are you sure want to change the version to ${this.state.selectedVmVersion.version}?\n\nThe VM will be destroyed and rebuilt if currently deployed, please ensure any work needing to be saved has been added to the Project or Output drives.`, async () => {
      await this.datalabFacade.changeVmType(this.state.vm.VMname, this.state.vm.type, this.state.selectedVmVersion.version, this.props.user.pod)
      this.props.showSnackbarInProgress(`Change Virtual Machine ${this.state.vm.VMname} version to ${this.state.selectedVmVersion.version} in progress...`)
      this.setState({ settingVmVersion: false, vm: { ...this.state.vm, status: 'BUILDING' } })
    })
  }

  handleStartVM = () => {
    this.props.askForConfirmationListener('Are you sure you want to start this VM?', async () => {
      await this.datalabFacade.startVM(this.state.vm.VMname, this.props.user.pod, this.props.showSnackbarTracking)
      this.setState({ powerState: 'STARTING' })
    })
  }

  handleStopVM = () => {
    this.props.askForConfirmationListener('Are you sure you want to stop this VM?', async () => {
      await this.datalabFacade.stopVM(this.state.vm.VMname, this.props.user.pod)
      this.setState({ powerState: 'DEALLOCATING' })
      this.props.showSnackbarInProgress(`Stop Virtual Machine (${this.state.vm.VMname}) in progress...`)
    })
  }

  handleSetVmBypassShutdown = () => {
    this.props.askForConfirmationListener(
      this.state.selectedBypassDays === 0
        ? 'Are you sure you want to reset the bypass to default? The machine will be automatically shutdown tonight'
        : `Are you sure you want to bypass the automatic shutdown of this VM for ${this.state.selectedBypassDays} day(s)?`,
      async () => {
        const result = await this.datalabFacade.setVmBypassShutdown(this.state.vm.VMname, this.state.selectedBypassDays)
        this.props.showSnackbarSuccess('Bypass VM shutdown succeeded')
        this.setState({ shutdownBypassUntil: result.shutdownBypassUntil, settingShutdownBypass: false })
      }
    )
  }

  handleRestartVm = () => {
    this.props.askForConfirmationListener('Are you sure you want to restart this VM?', async () => {
      await this.datalabFacade.restartVm(this.state.vm.VMname, this.props.user.pod, this.props.showSnackbarTracking)
      this.setState({ powerState: 'RESTARTING' })
    })
  }

  // callback if the VM is being reset while it's in "READY" status
  handleRebuild = () => {
    this.props.askForConfirmationListener(`Are you sure you want to rebuild this VM?\n\nThe VM will be destroyed and rebuilt from scratch, please ensure that any work needing to be saved has been added to the Project or Output drives. ${(!this.state.currentVmVersionOption.availableForRebuild) ? 'WARNING: The current VM version is no longer available, the VM will be rebuilt from the latest version.' : ''}`, async () => {
      await this.datalabFacade.rebuildVm(this.state.vm.VMname, this.props.user.pod)
      this.props.showSnackbarInProgress(`Rebuild Virtual Machine (${this.state.vm.VMname}) in progress...`)
      this.setState({ vm: { ...this.state.vm, status: 'BUILDING' } })
    })
  }

  // callback if the VM is being reset while it's in "DORMANT" or "ERROR" status
  handleRehydrate = () => {
    this.props.askForConfirmationListener('Are you sure you want to rebuild this VM?\n\nThe VM will be destroyed and rebuilt from scratch. ', async () => {
      await this.datalabFacade.rebuildVm(this.state.vm.VMname, this.props.user.pod)
      this.props.showSnackbarInProgress(`Rebuild Virtual Machine (${this.state.vm.VMname}) in progress...`)
      this.setState({ vm: { ...this.state.vm, status: 'BUILDING' } })
    })
  }

  powerStateCallback = (powerState) => {
    this.setState({ powerState })
    this.props.dispatch({ type: StoreAction.ACKNOwLEDGE_VM_STATE_CHANGED })
  }

  sleep = (millis) => new Promise((resolve) => setTimeout(resolve, millis))

  shutdownBypassUntilCallback = (shutdownBypassUntil) => {
    this.setState({ shutdownBypassUntil })
  }

  redeploymentDueDateCallback = (redeploymentDueDate) => {
    this.setState({ redeploymentDueDate })
  }

  render() {
    const { projectDetails = undefined } = this.state.vm || {}
    let projectUsage
    let projectUsagePercent
    if (projectDetails) {
      [projectUsage, projectUsagePercent] = formatSizeUsage(projectDetails.projectUsageDatalake, projectDetails.projectStorageSizeDatalake)
      console.log(projectUsage, projectUsagePercent)
    }
    return (
      <>

        {this.state.vm && this.state.vm.status === 'DORMANT'
                    && (
                    <div className="action-button-container">
                      <Typography variant="subtitle2" component="p">
                        <i style={{ color: '#d6a30b' }} className="fas fa-exclamation-triangle" />
&nbsp;&nbsp;The VM is currently dormant. It cannot be accessed until the machine is rebuilt.
                      </Typography>
                    </div>
                    )}
        {this.state.vm && this.state.vm.status === 'ERROR'
                    && (
                    <div className="action-button-container">
                      <Typography variant="subtitle2" component="p">
                        <i style={{ color: '#c61400' }} className="fas fa-exclamation-triangle" />
&nbsp;&nbsp;The VM is currently in an error state. Some options may not be available until the machine is rebuilt. Rebuild the machine to attempt to resolve the error.
                      </Typography>
                    </div>
                    )}
        {this.state.vm && this.state.vm.status === 'BUILDING'
                    && (
                    <div className="action-button-container">
                      <Typography variant="subtitle2" component="p">
                        <i style={{ color: '#00698f' }} className="fas fa-exclamation-triangle" />
&nbsp;&nbsp;The VM is currently building. Please wait for the build process to complete.
                      </Typography>
                    </div>
                    )}

        {((this.state.vm && this.state.vm.status === 'ERROR') || (this.state.vm && this.state.vm.status === 'DORMANT'))
                    && (
                    <div className="action-button-container">
                      <Button variant="contained" color="primary" onClick={this.handleRehydrate.bind(this)}>
                        Rebuild Now&nbsp;&nbsp;
                        <i className="fas fa-hammer" />
                      </Button>

                    </div>
                    )}

        <Grid container direction="row" alignItems="stretch" className="details-grid">
          {this.state.vm && this.state.vm.status === 'READY'

                        && (
                        <Grid item xs={12}>
                          {!this.state.currentVmVersionOption.latest
                                && (
                                <Alert color="warning">
                                  {
                                        this.state.currentVmVersionOption.availableForRebuild
                                          ? `Warning: The currently selected VM version (${this.state.currentVmVersionOption.version}) is being phased out or is an experimental version. See Desktop Version settings below.`
                                          : `Warning: The currently selected VM version (${this.state.currentVmVersionOption.version}) is no longer available. The VM will automatically upgrade to the latest version during next rebuild.`
                                    }
                                </Alert>
                                )}
                        </Grid>
                        )}
          <Grid item xs={5}>
            {this.state.loading ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Project Details
                    </Typography>
                  </SectionHeader>
                  <ComplexAttributeRow keyValuePairs={[
                    { header: 'Project Name', value: this.state.vm.projectDetails.projectName },
                    { header: 'Project ID', value: this.state.vm.project },
                    { header: 'Project Storage Account', value: this.state.vm.projectDetails.projectStore },
                    { header: 'Product Storage Account', value: this.state.vm.productStorageAccountName },
                    { header: 'Description', value: this.state.vm.projectDetails.description },
                    { header: 'Data Lake Quota (TiB)', value: this.state.vm.projectDetails.projectStorageSizeDatalake },
                    { header: 'Data Lake Used (TiB)', value: `${projectUsage} (${projectUsagePercent})`},
                    { header: 'Start Date', value: this.state.vm.projectDetails.startDate, date: true },
                    { header: 'End Date', value: this.state.vm.projectDetails.endDate, date: true },
                  ]}
                  />
                </UnscrollablePaper>
              )}
            {this.state.loading ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Machine Details
                    </Typography>
                  </SectionHeader>
                  <ComplexAttributeRow keyValuePairs={[
                    { header: 'Machine Name', value: this.state.vm.VMname },
                    { header: 'Machine Status', value: this.state.vm.status, status: true },
                    { header: 'Date Registered', value: this.state.vm.buildDate, date: true },
                    { header: 'Date Last Rebuilt', value: this.state.vm.destroyDate, date: true },
                    { header: 'Type', value: this.state.vm.type },
                    { header: 'Size', value: this.state.vm.size },
                    { header: 'Version', value: this.state.vm.version },
                    { header: 'Activated', value: this.state.vmActive, boolean: true },
                    { header: 'Local Disk (GB)', value: this.state.vm.localDiskSize },

                  ]}
                  />
                </UnscrollablePaper>
              )}
            {this.state.loading ? <SkeletonArrayRow />
              : (
                <ScrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Project Members (
                      {this.state.vm.projectDetails.users.length}
                      )
                    </Typography>
                  </SectionHeader>
                  <ArrayAttributeRow
                    header="Users"
                    columnHeaders={[{ name: 'Username', key: 'userName' }]}
                    keyValuePairs={this.state.vm.projectDetails.users.map((u) => ({ userName: u }))}
                    rowKey="userName"
                  />
                </ScrollablePaper>
              )}
          </Grid>

          <Grid item xs={7}>
            {this.state.loading ? <SkeletonArrayRow />

              : this.state.vm && this.state.vm.status !== 'DESTROYED' && (
              <>
                {this.state.vm.status === 'READY' && (
                <>
                  <UnscrollablePaper variant="outlined">
                    <SectionHeader>

                      <Typography variant="h6" component="h3">
                        Power State
                      </Typography>

                      <div className="sub-action-button-container">
                        <Button variant="outlined" color="primary" onClick={this.handleStopVM.bind(this)} disabled={this.state.powerState !== 'RUNNING'}>
                          Stop VM&nbsp;&nbsp;
                          <i className="fas fa-stop" />
                        </Button>
                        <Button variant="outlined" color="primary" onClick={this.handleStartVM.bind(this)} disabled={this.state.powerState !== 'DEALLOCATED'}>
                          Start VM&nbsp;&nbsp;
                          <i className="fas fa-play" />
                        </Button>
                        <Button variant="outlined" color="primary" onClick={this.handleRestartVm.bind(this)} disabled={this.state.powerState !== 'RUNNING'}>
                          Restart VM&nbsp;&nbsp;
                          <ReplayIcon />
                        </Button>
                        <HelpIconButton
                          color="primary"
                          onClick={() => this.setState({ showingPowerHelp: true })}
                          aria-label="virtual machine power help"
                          size="large"
                        >
                          <HelpIcon />
                        </HelpIconButton>
                      </div>
                    </SectionHeader>
                    {this.state.showingPowerHelp
                                                && (
                                                <HelpDialog
                                                  open={this.state.showingPowerHelp}
                                                  callback={() => this.setState({ showingPowerHelp: false })}
                                                  title="Power"
                                                  message="Power state refers to whether the machine is physically turned on or off. It must be turned on to successfully launch a session."
                                                />
                                                )}
                    <ComplexAttributeRow
                      refreshTimestamp={this.props.refreshVmState}
                      keyValuePairs={[
                        {
                          header: 'Power State',
                          lazy: () => this.datalabFacade.getVmState(this.state.vm.VMname),
                          lazyKey: 'status',
                          status: true,
                          dataCallback: this.powerStateCallback.bind(this),
                          value: this.state.powerState,
                        },
                      ]}
                    />
                  </UnscrollablePaper>
                  <UnscrollablePaper variant="outlined">
                    <SectionHeader>

                      <Typography variant="h6" component="h3">
                        Scheduled Shutdown
                      </Typography>

                      <div className="sub-action-button-container">
                        {this.state.settingShutdownBypass
                          ? (
                            <Button variant="outlined" color="primary" onClick={() => this.setState({ settingShutdownBypass: false })}>
                              Cancel&nbsp;&nbsp;
                              <i className="fas fa-times" />
                            </Button>
                          )
                          : (
                            <Button variant="outlined" color="primary" onClick={() => this.setState({ settingShutdownBypass: true })} disabled={this.state.shutdownBypassUntil === undefined || this.state.vm.status !== 'READY'}>
                              Bypass Shutdown&nbsp;&nbsp;
                              <i className="far fa-clock" />
                            </Button>
                          )}
                        <HelpIconButton
                          color="primary"
                          onClick={() => this.setState({ showingShutdownHelp: true })}
                          aria-label="virtual machine shutdown help"
                          size="large"
                        >
                          <HelpIcon />
                        </HelpIconButton>
                      </div>
                    </SectionHeader>
                    {this.state.showingShutdownHelp
                                                && (
                                                <HelpDialog
                                                  open={this.state.showingShutdownHelp}
                                                  callback={() => this.setState({ showingShutdownHelp: false })}
                                                  title="Scheduled Shutdown"
                                                  message={`All machines are automatically shut down at ${hourInLocalTime(22, this.state.vm.localTimeZone)} every night. You can choose to bypass the shutdown for up to 3 nights at a time. You cannot bypass a shutdown the night a rebuild is scheduled.`}
                                                />
                                                )}

                    {this.state.settingShutdownBypass
                      ? (
                        <>
                          <div className="margin20">
                            <Typography variant="caption" component="p">
                              Automatic shutdown of this Virtual Machine can be bypassed for up to 3 days. It will be bypassed until
                              {' '}
                              {hourInLocalTime(22, this.state.vm.localTimeZone)}
                              {' '}
                              of the selected day.
                            </Typography>
                            <ButtonGroup
                              sx={{
                                marginTop: '30px',
                              }}
                              color="primary"
                              aria-label="bypass shutdown days button group"
                            >
                              <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 0 })} variant={this.state.selectedBypassDays === 0 ? 'contained' : 'outlined'}>
                                Default (Tonight)
                              </DaySelectorButton>
                              <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 1 })} variant={this.state.selectedBypassDays === 1 ? 'contained' : 'outlined'}>
                                {`${new Date(new Date().getTime() + 86400000).toLocaleDateString('default', { weekday: 'long' })} (1 Night)`}
                              </DaySelectorButton>
                              <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 2 })} variant={this.state.selectedBypassDays === 2 ? 'contained' : 'outlined'}>
                                {`${new Date(new Date().getTime() + 86400000 * 2).toLocaleDateString('default', { weekday: 'long' })} (2 Nights)`}
                              </DaySelectorButton>
                              <DaySelectorButton onClick={() => this.setState({ selectedBypassDays: 3 })} variant={this.state.selectedBypassDays === 3 ? 'contained' : 'outlined'}>
                                {`${new Date(new Date().getTime() + 86400000 * 3).toLocaleDateString('default', { weekday: 'long' })} (3 Nights)`}
                              </DaySelectorButton>
                            </ButtonGroup>

                          </div>
                          <SaveButtonContainer>
                            <Button variant="contained" color="primary" onClick={this.handleSetVmBypassShutdown.bind(this)} disabled={this.state.selectedBypassDays === undefined}>
                              Submit
                            </Button>
                          </SaveButtonContainer>
                        </>
                      )
                      : (
                        <ComplexAttributeRow
                          refreshTimestamp={this.props.timestamp}
                          keyValuePairs={[
                            {
                              header: 'Next Due',
                              lazy: () => this.datalabFacade.getVmBypassShutdown(this.state.vm.VMname),
                              lazyKey: 'shutdownBypassUntil',
                              dateFutureDayOrTonight: [20, this.state.vm.localTimeZone],
                              dataCallback: this.shutdownBypassUntilCallback.bind(this),
                              value: this.state.shutdownBypassUntil,
                            },
                          ]}
                        />
                      )}
                  </UnscrollablePaper>
                  <UnscrollablePaper variant="outlined">
                    <>
                      <SectionHeader>

                        <Typography variant="h6" component="h3">
                          Scheduled Rebuild
                        </Typography>

                        <div className="sub-action-button-container">
                          <Button variant="outlined" color="primary" onClick={this.handleRebuild.bind(this)} disabled={this.state.vm.status !== 'READY'}>
                            Rebuild Now&nbsp;&nbsp;
                            <i className="fas fa-hammer" />
                          </Button>
                          <HelpIconButton
                            color="primary"
                            onClick={() => this.setState({ showingRebuildHelp: true })}
                            aria-label="virtual machine rebuild help"
                            size="large"
                          >
                            <HelpIcon />
                          </HelpIconButton>
                        </div>
                      </SectionHeader>
                      {this.state.showingRebuildHelp
                                                    && (
                                                    <HelpDialog
                                                      open={this.state.showingRebuildHelp}
                                                      callback={() => this.setState({ showingRebuildHelp: false })}
                                                      title="Scheduled Rebuild"
                                                      message='All machines are automatically rebuilt every 30 days for security and maintenance purposes. You cannot delay the rebuild but you can choose to rebuild the machine earlier than the scheduled date, resetting the schedule to 30 days from now. If the machine has not been used in the last 21 days when the automated rebuild occurs the machine will be marked as "dormant". This means the machine is destroyed, but not rebuilt. However you can build the machine again at any time.'
                                                    />
                                                    )}

                      <ComplexAttributeRow
                        refreshTimestamp={this.props.timestamp}
                        keyValuePairs={[
                          {
                            header: 'Next Due',
                            lazy: () => this.datalabFacade.getVmBypassShutdown(this.state.vm.VMname),
                            lazyKey: 'redeploymentDueDate',
                            dateTime: true,
                            dataCallback: this.redeploymentDueDateCallback.bind(this),
                            value: this.state.redeploymentDueDate,
                          },
                        ]}
                      />

                      {this.state.redeploymentDueDate
                                                    && <RebuildProgress rebuildDate={new Date(this.state.redeploymentDueDate)} />}

                    </>
                  </UnscrollablePaper>
                </>
                )}
                {this.state.vm.status !== 'BUILDING' && (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>

                    <Typography variant="h6" component="h3">
                      Desktop Version

                    </Typography>

                    {!this.state.currentVmVersionOption.latest
                                                && <Alert color="info">{this.state.currentVmVersionOption.deprecationText}</Alert>}

                    <div className="sub-action-button-container">

                      {this.state.settingVmVersion
                        ? (
                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmVersion: false })}>
                            Cancel&nbsp;&nbsp;
                            <i className="fas fa-times" />
                          </Button>
                        )
                        : (
                          <Button variant="outlined" color="primary" onClick={() => this.setState({ settingVmVersion: true })} disabled={!(this.state.vm.status === 'READY' || this.state.vm.status === 'DORMANT' || this.state.vm.status === 'ERROR')}>
                            Change VM Version
                          </Button>
                        )}
                      <Button
                        variant="text"
                        onClick={() => this.setState({ showingVmVersionHelp: true })}
                        aria-label="virtual machine version help"
                        size="large"
                        endIcon={<HelpIcon />}
                      >
                        Versions
                      </Button>
                    </div>
                  </SectionHeader>
                  {this.state.showingVmVersionHelp
                                            && (
                                            <HelpDialogListed
                                              open={this.state.showingVmVersionHelp}
                                              callback={() => this.setState({ showingVmVersionHelp: false })}
                                              title="Available Virtual Machine Desktop Versions"
                                              headers={[
                                                { formatted: 'Version', key: 'version' },
                                                { formatted: 'Included Software', key: 'softwareText' },
                                                { formatted: 'Notes', key: 'deprecationText' },
                                              ]}
                                              rows={this.state.vmVersionOptions}
                                              rowKey="type"
                                              message="Desktop versions are released annually and include the most up to date software (security patches are always applied to all desktop versions), plan your migration to the newest version: Previous year versions are no longer available shortly after a new version is released. New major software versions can introduce breaking changes, issues should be reported prior to the previous years version being removed, so give yourself enough time to try out the new version."
                                            />
                                            )}
                  {this.state.settingVmVersion
                    ? (
                      <div>
                        <div className="margin20">
                          <Typography style={{ padding: '10px 0px 10px 0px', fontSize: '0.8em' }} variant="caption1" component="p">
                            * Changing the version requires a full rebuild of the VM
                          </Typography>
                          <AutocompleteBox>
                            <Autocomplete
                              id="autocomplete-vmVersionOptions"
                              options={this.state.selectableVmVersions}
                              getOptionLabel={(option) => `${option.version}${option.latest ? ' (Latest)' : ''}`}
                              style={{ width: 600 }}
                              defaultValue={this.state.currentVmVersionOption.availableForRebuild ? this.state.currentVmVersionOption : this.state.selectableVmVersions.find((e) => e.latest)}
                              onChange={(event, values) => { this.setState({ selectedVmVersion: values || null }) }}
                              renderInput={(params) => (
                                <FormLabel {...params} label="VM Version" variant="outlined" fullWidth />

                              )}
                            />
                          </AutocompleteBox>
                        </div>
                        <div className="margin20">
                          <SaveButtonContainer>
                            <Button
                              className="margin20"
                              variant="contained"
                              color="primary"
                              onClick={this.handleChangeVmVersion.bind(this)}
                              disabled={
                                                                this.state.selectedVmVersion === this.state.currentVmVersionOption
                                                                || this.state.selectedVmImageVersion === null
}
                            >
                              Submit
                            </Button>
                          </SaveButtonContainer>

                        </div>
                      </div>
                    )
                    : (
                      <ComplexAttributeRow
                        refreshTimestamp={this.props.timestamp}
                        keyValuePairs={[
                          {
                            header: 'Type',
                            lazy: null,
                            lazyKey: 'type',
                            dataCallback: null,
                            value: this.state.vm.type,
                          },
                          {
                            header: 'Version',
                            lazy: null,
                            lazyKey: 'type',
                            dataCallback: null,
                            value: this.state.currentVmVersionOption.version,
                          },
                          {
                            header: 'Included Software',
                            lazy: null,
                            lazyKey: 'softwareText',
                            dataCallback: null,
                            value: this.state.currentVmVersionOption.softwareText,
                          },
                          {
                            header: 'Notes',
                            lazy: null,
                            lazyKey: 'deprecationText',
                            dataCallback: null,
                            value: this.state.currentVmVersionOption.deprecationText,
                          },
                        ]}
                      />
                    )}
                </UnscrollablePaper>
                )}
              </>
              )}
          </Grid>

        </Grid>

      </>
    )
  }
}

export default wrap((connect(mapStateToProps)(VmDetailsScreen)))

function mapStateToProps(state) {
  return {
    refreshVmState: state.refreshVmState,
  }
}
