import React from 'react'
import { styled } from '@mui/material/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { SnackbarProvider } from 'notistack'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import AppRouter from './Router'
import NavBar from './components/NavBar'
import SideNav from './components/SideNav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'
import '../resources/styles/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import AsyncHandler from './dataService/AsyncHandler'
import IdleActivityTimer from './components/IdleActivityTimer'
import EnvironmentWatermark from './components/EnvironmentWatermark'
import Theme from './themes/Theme'
import PlatformThemes from './themes/PlatformThemes'
import { FeaturesProvider } from './hooks/useFeatures'
import { SeadAccountInfo, useLoggedInUser } from './hooks/useLoggedInUser'
import ErrorBoundary from './components/ErrorBoundary'
import AppAlerts from './components/AppAlerts'
import { SeadApiProvider } from './hooks/useSeadApi'
import { isDataLab } from './util/BusinessUtils'

const Root = styled('div')(() => ({
  display: 'flex',
}))

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
}))

// Get the CSP nonce set by the server within the HTML head and input to emotion
// css library
const cspNonceTag: HTMLMetaElement | null = document.head.querySelector('[property~=csp-nonce]')
if (!(cspNonceTag instanceof HTMLMetaElement)) {
  throw new Error('CSP nonce meta tag not found')
}
const cache = createCache({
  key: 'css',
  nonce: cspNonceTag.content,
})

const getTheme = (user?: SeadAccountInfo) => (
  isDataLab(user) ? PlatformThemes.DATALAB : PlatformThemes.SEAD
)

/**
 * This is the top level application component
 */
function App() {
  const { user } = useLoggedInUser()

  return (
    <CacheProvider value={cache}>
      <Router>
        <CssBaseline />
        <Theme platformTheme={getTheme(user)}>
          <SnackbarProvider
            maxSnack={10}
          >
            <ErrorBoundary>
              <FeaturesProvider>
                <SeadApiProvider>
                  <EnvironmentWatermark />
                  <Root>
                    <AsyncHandler />
                    {user && <IdleActivityTimer />}
                    <NavBar />
                    <CssBaseline />
                    <SideNav />
                    <Main>
                      <AppAlerts />
                      <AppRouter />
                    </Main>
                  </Root>
                </SeadApiProvider>
              </FeaturesProvider>
            </ErrorBoundary>
          </SnackbarProvider>
        </Theme>
      </Router>
    </CacheProvider>
  )
}

export default App
