import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useLoggedInUser } from '../hooks/useLoggedInUser'
import Alert from './Alert'

const LOGOUT_AFTER = 1000 * 60 * 30 // 30 minutes
const WARN_AFTER = 1000 * 60 * 29 // 29 minutes

/**
 * It tracks the users' last activity as a timestamp in local storage. If it
 * exceeds the WARN_AFTER minutes it shows a warning dialog. If it exceeds the
 * LOGOUT_AFTER minutes it automatically logs out the user due to inactivity.
 * The component should be declared in the root of the app.
 * @returns the IdleActivityTimer component
 */
export default function IdleActivityTimer() {
  const [showWarnDialog, setShowWarnDialog] = useState(false)
  const [remaining, setRemaining] = useState<number>()
  const { instance: msalInstance } = useMsal()
  const { user } = useLoggedInUser()

  /**
   * Gets the time elapsed since the user was active
   * @returns Time in ms since user was last active
   */
  function getTimeSinceLastActive(): number {
    const currentDate = new Date()
    const dateStored = window.localStorage.getItem('datalabLastActive')
    if (!dateStored) {
      // another tab already logged us out
      msalInstance.logoutRedirect({ account: user })
      throw new Error('No activity time in local storage, assume another tab has logged us out.')
    }
    const datalabLastActive = new Date(parseInt(dateStored, 10))
    const timePassedSinceLastActive = currentDate.getTime() - datalabLastActive.getTime()
    return timePassedSinceLastActive
  }

  /**
   * Sets the last active time in local storage
   * @param force - Sets the last active time as now regardless of user activity
   */
  function updateLastActive(force?: boolean) {
    if (force || window.localStorage.getItem('datalabLastActive')) {
      setShowWarnDialog(false)
      // add to local storage to track activity across tabs
      const date = new Date()
      window.localStorage.setItem('datalabLastActive', date.getTime().toString())
    }
  }

  /**
   * Function set as callback for common event listeners that indicates the user is active (mouse move)
   */
  function userEventListener() {
    updateLastActive()
  }

  useEffect(() => {
    document.addEventListener('mousemove', userEventListener, false)
    document.addEventListener('mousedown', userEventListener, false)
    document.addEventListener('keypress', userEventListener, false)
    document.addEventListener('mousemove', userEventListener, false)
    document.addEventListener('wheel', userEventListener, false)
    document.addEventListener('touchmove', userEventListener, false)

    // set initial to now
    updateLastActive(true)

    // set the DOM to update every 1 second in order to check idle time
    setInterval(() => {
      const timeSinceLastActive = getTimeSinceLastActive()
      setRemaining(Math.floor((LOGOUT_AFTER - timeSinceLastActive) / 1000))
      if (timeSinceLastActive > LOGOUT_AFTER) {
        msalInstance.logoutRedirect({ account: user })
      } else if (timeSinceLastActive > WARN_AFTER) {
        setShowWarnDialog(true)
      }
    }, 1000)
  }, [])

  return showWarnDialog
    ? (
      <Alert
        open={showWarnDialog}
        title="Are you still there?"
        content={`You will be automatically logged out after ${remaining} seconds`}
        hideButtons
      />
    )
    : null
}

