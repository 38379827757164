import React from 'react'
import { SeadAccountInfo } from '../../hooks/useLoggedInUser'
import LinkProductsProjectsScreen from './LinkProductsProjectsScreen'
import type { CommonProps } from '../../../types/CommonProps'
import type DatalabFacade from '../../dataService/DatalabFacade'

const submitApi = async (
  datalabFacade: ReturnType<typeof DatalabFacade>,
  projectId: string,
  podId: string,
  { updatedLinks }: {updatedLinks: string[]}
) => datalabFacade.linkProjectToProducts(projectId, updatedLinks)

const loadData = async (
  datalabFacade: ReturnType<typeof DatalabFacade>,
  user: SeadAccountInfo,
  targetId: string
): Promise<{ existingLinks: string[], counterparts: string[]}> => {
  const [productLinks, products] = await Promise.all([
    datalabFacade.list({
      entity: 'projectProductLinks',
      requestBody: {
        podId: user.pod,
        fields: ['productName'],
        filters: [{
          property: 'projectId', condition: '$eq', value: targetId,
        }],
      },
    }),
    datalabFacade.list({
      entity: 'products',
      requestBody: {
        podId: user.pod,
        fields: ['name'],
      },
    }),
  ])

  return {
    existingLinks: productLinks.data.map((existingLink) => existingLink.productName),
    counterparts: products.data.map((counterpart) => counterpart.name),
  }
}

/**
 * Allows selecting products to be linked with a project
 * @param props - Component props
 * @returns a LinkProductsScreen component
 */
export default function LinkProductsScreen(props: Readonly<CommonProps>) {
  return (
    <LinkProductsProjectsScreen
      target="project"
      targetPrimaryKey="projectId"
      counterpartCollectionName="products"
      selectedLinksMessage="Analysts in this project will be granted read-only access to the products listed here"
      submitApi={submitApi}
      loadData={loadData}
      {...props}
    />
  )
}

