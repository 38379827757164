/* eslint-disable react/prop-types */
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { StoreAction } from '../enums/StoreAction'
import { getRuntimeConfig } from '../util/Config'
import { useSnackbars } from '../hooks/useSnackbars'
import { sleep } from '../util/ReactUtils'
import { useSeadApi } from '../hooks/useSeadApi'

const config = getRuntimeConfig()

const TIMEOUT = config.ASYNC_POLL_TIMEOUT

function roundToNearest30(date = new Date()) {
  const seconds = 30
  const ms = 1000 * seconds
  return new Date(Math.ceil(date.getTime() / ms) * ms)
}

/**
 * Since most create/update operations are async this component handles the polling and feedback to the user
 * when an operation is complete
 * Inject this into the root component to handle the ongoing operations
 */
function AsyncHandler({ operations, dispatch }) {
  const {
    closeSnackBar,
    showSnackbarSuccess,
    showSnackbarFail,
    showSnackbarWarning,
  } = useSnackbars()

  const { getEventsByIds } = useSeadApi()

  let mounted = false

  useEffect(() => {
    mounted = true
    return function cleanup() {
      mounted = false
    }
  })

  const pollEvents = async () => {
    if (operations.length > 0) {
      const eventIds = operations.map((op) => op.eventId)
      const events = await getEventsByIds(eventIds.join())

      events.forEach((event) => {
        // eslint-disable-next-line no-underscore-dangle
        const operation = operations.find((o) => o.eventId === event._id)
        if (operation.snackbar && (event.status === 'Succeeded' || event.status === 'Failed')) {
          closeSnackBar(operation.snackbar.key)
        } else if (operation.snackbar) {
          const ref = document.getElementById(operation.snackbar.id)
          const estimatedTime = 180000
          const now = new Date().getTime()
          const timeStarted = operation.time
          const durationSoFar = now - timeStarted
          let estimatedTimeLeft = roundToNearest30(new Date(estimatedTime - durationSoFar))
          if (estimatedTimeLeft.getTime() <= 0) {
            estimatedTimeLeft = 'Taking longer than usual...'
          } else {
            estimatedTimeLeft = `Estimated time left: ${String(estimatedTimeLeft.getUTCMinutes())}m ${String(estimatedTimeLeft.getUTCSeconds())}s`
          }

          if (ref) ref.innerText = `${event.action} (${event.entityName}) ${estimatedTimeLeft}`
        }

        if (event.status === 'Succeeded') {
          if (event.action === 'Update Linked Products') {
            const maxProductsToDisplay = 3
            const products = (JSON.stringify(event.entityName))
              .substring(JSON.stringify(event.entityName).indexOf('[') + 1, JSON.stringify(event.entityName)
                .lastIndexOf(']')).trim().split(',')
            // if number of products exceeds three, then show only the first 3 products that have been linked.
            if (products.length > maxProductsToDisplay) {
              const firstThree = products.slice(0, maxProductsToDisplay)
              dispatch({ type: StoreAction.REMOVE_OPERATION, operation })
              showSnackbarSuccess(
                `${event.action} [ ${firstThree} ] and ${products.length - maxProductsToDisplay} others succeeded`
              )
            } else {
              dispatch({ type: StoreAction.REMOVE_OPERATION, operation })
              showSnackbarSuccess(`${event.action} (${event.entityName}) succeeded`)
            }
          } else {
            dispatch({ type: StoreAction.REMOVE_OPERATION, operation })
            showSnackbarSuccess(`${event.action} (${event.entityName}) succeeded`)
          }

          if (['Start VM', 'Stop VM', 'Restart VM', 'Activate VM'].includes(event.action)) {
            dispatch({ type: StoreAction.NOTIFY_VM_STATE_CHANGED })
          }
        } else if (event.status === 'Failed') {
          dispatch({ type: StoreAction.REMOVE_OPERATION, operation })
          showSnackbarFail(`${event.action} (${event.entityName}) failed`)
        } else {
          const expiredBefore = new Date().getTime() - TIMEOUT
          if (!operation.time || operation.time < expiredBefore) {
            dispatch({ type: StoreAction.REMOVE_OPERATION, operation })
            showSnackbarWarning(`${event.action} (${event.entityName}) timed out`)
          }
        }
      })
    }

    await sleep(30000)
    if (mounted) {
      pollEvents()
    }
  }

  pollEvents()

  return null
}

// Using Redux store to manage global state
// https://daveceddia.com/how-does-redux-work/
function mapStateToProps(state) {
  return {
    operations: state.operations,
  }
}

export default connect(mapStateToProps)(AsyncHandler)
