import { SeadAccountInfo } from '../hooks/useLoggedInUser'

/**
 * Determines if the app should display as DataLab, instead of SEAD
 * @param user - the user account
 * @returns true if the app should display as DataLab given the user account or
 * the URL as a fallback
 */
export function isDataLab(user?: SeadAccountInfo): boolean {
  // Infer the users pod claim or from the URL if unauthenticated
  if (user) {
    return user.pod === 'ABS'
  }
  return !!/localhost|datalab/.exec(window.location.origin)
}
