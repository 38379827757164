import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { getRuntimeConfig } from './js/util/Config'

const config = getRuntimeConfig()

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: config.AZURE_APPLICATION_ID,
    authority: config.AZURE_AD_AUTHORITY_URL,
    redirectUri: `${window.location.origin}/aad-callback`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
}

/**
 * Initialise MSAL. It handles the login redirect and sets the active account.
 * @returns the MSAL client which should be passed to the MsalProvider wrapper component
 */
export async function setupMsal(): Promise<PublicClientApplication> {
  const msalInstance = new PublicClientApplication(configuration)
  await msalInstance.initialize()
  const authResult = await msalInstance.handleRedirectPromise()
  if (authResult) {
    msalInstance.setActiveAccount(authResult.account)
  }
  return msalInstance
}
