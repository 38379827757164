import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  Link as MuiLink, Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import '@fortawesome/fontawesome-free/css/all.css'
import '../../resources/styles/main.css'
import Button from '@mui/material/Button'
import CelebrationIcon from '@mui/icons-material/Celebration'
import InfoIcon from '@mui/icons-material/Info'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import { useMsal } from '@azure/msal-react'
import { RoleDisplayName } from '../enums/RoleDisplayName'
import datalabAdminLogo from '../../resources/images/DataLab Admin (280x60).svg'
import seadAdminLogo from '../../resources/images/SEAD Admin (280x60).svg'
import datalabAnalystLogo from '../../resources/images/DataLab (280x60).svg'
import seadAnalystLogo from '../../resources/images/SEAD (280x60).svg'
import { Role } from '../enums/Role'
import { SeadAccountInfo, useLoggedInUser } from '../hooks/useLoggedInUser'
import { isDataLab } from '../util/BusinessUtils'
import DrawerWhatsNew from './DrawerWhatsNew'

const PREFIX = 'NavBar'

const classes = {
  root: `${PREFIX}-root`,
  navLink: `${PREFIX}-navLink`,
  navItem: `${PREFIX}-navItem`,
}

const StyledNavbar = styled(Box)((
  {
    theme,
  }
) => ({
  position: 'fixed',
  display: 'flex',
  width: '100%',
  height: '50px',
  zIndex: 1100,
  boxShadow: `0 1px 5px -3px ${theme.palette.primary.light}`,
  alignItems: 'center',
  color: 'black',
  backgroundColor: '#FFF',
  [`& .${classes.navItem}`]: {
    color: theme.palette.primary.main,
    borderRadius: 0,
    height: '30px',
    fontSize: '11px',
    fontWeight: '450',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}))

const getLogo = (user?: SeadAccountInfo) => {
  if (!user) {
    // Detect theme from the URL
    return isDataLab() ? datalabAnalystLogo : seadAnalystLogo
  }
  if (user.pod === 'ABS') {
    return user.roles.includes(Role.ANALYST)
      ? datalabAnalystLogo
      : datalabAdminLogo
  }
  return user.roles.includes(Role.ANALYST)
    ? seadAnalystLogo
    : seadAdminLogo
}

/**
 * The navigation bar component displayed at the top of the app
 */
function NavBar() {
  const [whatsNewOpen, setWhatsNewOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null as Element | null)
  const [menuOpen, setMenuOpen] = useState(false)

  const { user } = useLoggedInUser()
  const { instance: msalInstance } = useMsal()

  const logoImage = getLogo(user)

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.target as HTMLButtonElement)
    setMenuOpen(true)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
    setMenuOpen(false)
  }

  return (
    <StyledNavbar>
      <Box sx={{
        width: '200px',
        padding: '10px',
        '@media (max-width: 1100px)': {
          display: 'none',
        },
      }}
      >
        <Box
          sx={{ width: '100%' }}
          component="img"
          id="absLogo"
          src={logoImage}
          alt="ABSLogo"
        />
      </Box>
      <Box sx={{ marginLeft: 'auto' }} />
      {isDataLab(user)
        ? (
          <MuiLink
            variant="caption"
            sx={{
              '@media (max-width: 1000px)': {
                display: 'none',
              },
              textDecoration: 'none',
              textWrap: 'nowrap',
              fontSize: '11px',
              fontWeight: '450',
            }}
            target="_blank"
            rel="noreferrer"
            href="https://www.abs.gov.au/ausstats/abs@.nsf/mf/1406.0.55.003"
          >
            Always follow your Responsible Use of ABS Microdata obligations
          </MuiLink>
        )
        : (
          <MuiLink
            variant="caption"
            sx={{
              '@media (max-width: 1000px)': {
                display: 'none',
              },
              textDecoration: 'none',
              textWrap: 'nowrap',
              fontSize: '11px',
              fontWeight: '450',
            }}
            target="_blank"
            rel="noreferrer"
            href="https://www.abs.gov.au/about/data-services/secure-environment-analysing-data-sead/conditions-use"
          >
            Always abide by the SEAD Conditions of Use
          </MuiLink>
        )}
      <Button
        className={classes.navItem}
        variant="text"
        startIcon={<CelebrationIcon />}
        sx={{ textTransform: 'none', textWrap: 'nowrap', '.MuiButton-startIcon': { mr: '4px' } }}
        onClick={() => setWhatsNewOpen(true)}
      >
        What&apos;s New!
      </Button>

      <DrawerWhatsNew open={whatsNewOpen} closeCallback={() => setWhatsNewOpen(false)} />

      {user?.pod === 'ABS' ? (
        <Button
          className={classes.navItem}
          variant="text"
          startIcon={<InfoIcon />}
          sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
          href="https://www.abs.gov.au/ausstats/abs@.nsf/mf/1406.0.55.007"
          target="_blank"
        >
          About
        </Button>
      ) : (
        <Button
          className={classes.navItem}
          variant="text"
          startIcon={<InfoIcon />}
          sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
          href="https://www.abs.gov.au/about/data-services/secure-environment-analysing-data-sead"
          target="_blank"
        >
          About
        </Button>
      )}

      {user?.pod === 'ABS'
        ? (
          <Button
            className={classes.navItem}
            variant="text"
            startIcon={<VerifiedUserIcon />}
            sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
            href="https://www.abs.gov.au/about/legislation-and-policy/privacy/datalab-privacy-notice"
            target="_blank"
          >
            Privacy
          </Button>
        ) : (
          <Button
            className={classes.navItem}
            variant="text"
            startIcon={<VerifiedUserIcon />}
            sx={{ textTransform: 'none', '.MuiButton-startIcon': { mr: '4px' } }}
            href="https://www.abs.gov.au/about/legislation-and-policy/privacy/secure-environment-analysing-data-sead-privacy-notice"
            target="_blank"
          >
            Privacy
          </Button>
        )}
      {user
        && (
        <>
          <Button
            className={classes.navItem}
            disableRipple
            sx={{ textTransform: 'none' }}
            endIcon={<ArrowDropDownIcon />}
            variant="text"
            onClick={handleMenuClick}
          >
            {user.username}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <Box sx={{ m: '10px', width: '300px' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }} onClick={handleMenuClose}>
                {user.name}
              </Typography>
              <Typography sx={{ fontSize: '14px' }} onClick={handleMenuClose}>
                {user.username}
              </Typography>
              <Typography sx={{ fontSize: '14px' }} onClick={handleMenuClose}>
                {' '}
                <strong>Pod: </strong>
                {' '}
                {user.pod === 'ABS' ? 'ABS DataLab' : user.pod }
                {' '}
              </Typography>
              <Typography sx={{ fontSize: '14px' }} onClick={handleMenuClose}>
                <strong>Role(s):</strong>
                {' '}
                {user.roles.map((r) => RoleDisplayName[r]).join(', ')}
              </Typography>
            </Box>
            <MenuItem onClick={() => {
              // Trigger other tabs to logout
              window.localStorage.removeItem('datalabLastActive')
              msalInstance.logoutRedirect({
                account: user,
              })
            }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              <ListItemText> Sign Out</ListItemText>

            </MenuItem>
          </Menu>
        </>
        )}
    </StyledNavbar>
  )
}

export default NavBar
