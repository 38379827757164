"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findAllPaginatedQueryDto = void 0;
const zod_1 = require("zod");
const __1 = require("..");
const project_1 = require("./project");
const projectProductLinks_1 = require("./projectProductLinks");
const product_1 = require("./product");
const user_1 = require("./user");
const report_1 = require("./report");
const session_1 = require("./session");
const event_1 = require("./event");
const package_1 = require("./package");
const organisation_1 = require("./organisation");
const filterCondition = zod_1.z.enum([
    '$eq',
    '$ne',
    '$regex',
    '$gte',
    '$gt',
    '$lt',
    '$lte',
    '$elemMatch',
]);
const filterValue = zod_1.z.custom();
/**
 * Filters for the query, they are currently ANDed together. The
 * findAllPaginated function does not currently support other logical operators.
 */
const queryFilter = zod_1.z.object({
    /** The id of the project */
    property: zod_1.z.string(),
    condition: filterCondition,
    value: filterValue,
});
const queryResponseFields = {
    projects: project_1.projectDtoSchema,
    products: product_1.productDtoSchema,
    projectProductLinks: projectProductLinks_1.projectProductLinksDtoSchema,
    users: user_1.userDtoSchema,
    reports: report_1.reportDtoSchema,
    sessions: session_1.sessionDtoSchema,
    VMs: __1.vmDtoSchema,
    organisations: organisation_1.organisationDtoSchema,
    packages: package_1.packageDtoSchema,
    events: event_1.eventDtoSchema,
    tags: __1.tagDtoSchema,
};
const findAllPaginatedOptionsSchema = zod_1.z.object({
    /** Pod ID is required to apply row level filtering for end users. */
    podId: zod_1.z.string(),
    /** The page number */
    page: zod_1.z.number().min(1).optional(),
    /** The size of the page (rows) */
    pageSize: zod_1.z.number().optional(),
    /**
     * An array of filter specification objets to filter the result, if there are
     * multiple the filters are ANDed together
     */
    filters: zod_1.z.array(queryFilter).optional(),
    /**
     * Sort configuration for the query. Only supports one sort property - to
     * enable multiple we need to configure composite indexes in the collection
     */
    sort: zod_1.z.object({
        property: zod_1.z.string(),
        direction: zod_1.z.enum(['asc', 'desc']),
    }).optional(),
    /**
     * A string that will be used to run a regex search on all fields of the
     * collection
     */
    quickSearchQuery: zod_1.z.string().optional(),
    /**
     * Optional, calculates time range based on string description ie. Last Month
     */
    selectedTimeRange: zod_1.z.enum([
        'Last Week',
        'Last Month',
        'Last Three Months',
        'Last Six Months',
        'Last Year',
        'All Time',
    ]).optional(),
    /** Required if timeRange present. The key to filter timeRange on */
    timeRangeKey: zod_1.z.string().optional(),
    /**
     * Will limit the totalCount to max specified, count
     * operations are just as expensive as reading the entire result set - this
     * allows us to save RUs for large queries.
     */
    limitCount: zod_1.z.number().optional(),
    /**
     * Whether to count the total that would be returned by the query, e.g. set
     * to true on first page, not needed for subsequent pages (cache in client)
     */
    shouldCount: zod_1.z.boolean().optional(),
});
const findAllPaginatedQueryDto = zod_1.z.discriminatedUnion('entity', [
    zod_1.z.object({
        entity: zod_1.z.literal('projects'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.projects.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('products'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.products.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('projectProductLinks'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.projectProductLinks.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('users'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.users.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('reports'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.reports.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('sessions'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.sessions.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('VMs'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.VMs.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('organisations'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.organisations.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('packages'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.packages.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('events'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.events.keyof()),
        })),
    }),
    zod_1.z.object({
        entity: zod_1.z.literal('tags'),
        requestBody: findAllPaginatedOptionsSchema.merge(zod_1.z.object({
            fields: zod_1.z.array(queryResponseFields.tags.keyof()),
        })),
    }),
]).refine(
// requires timeRangeKey if selectedTimeRange is specified
(input) => (input.requestBody.selectedTimeRange ? input.requestBody.timeRangeKey : true), {
    message: 'timeRangeKey is required when selectedTimeRange is specified',
}).refine(
// requires pageSize if page is specified
(input) => (input.requestBody.page ? input.requestBody.pageSize : true), {
    message: 'pageSize is required when page is specified',
});
exports.findAllPaginatedQueryDto = findAllPaginatedQueryDto;
